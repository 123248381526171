<template>


    <div class="container">
    <!-- 页面选择 -->
    <div class="list-page">
      <el-tabs v-model="activePage" @tab-click="handleClick()">
        <el-tab-pane label="名片广场" name="/square/index"></el-tab-pane>
        <el-tab-pane label="公司名片" name="/square/companycard"></el-tab-pane>
      </el-tabs>
    </div>
    <!-- 分页面 -->
     <div class="content">
      <router-view></router-view>
    </div>
  </div>

  
</template>
<script>
  export default {
    data() {
      return {
        activePage: '/square/index'
      };
    },
    watch: {
       // 初始化当前选择的分类标签
     '$route': {
      handler: function(){
        this.activePage = this.$route.path;
        },
        immediate: true
      }
    },
    methods: {
      handleClick(tab, event) {
      if(this.activePage == this.$route.path) return;
      this.$router.replace({path: this.activePage});
    }
      }
  };
</script>

<style lang="scss" scoped>
.list-page{
  ::v-deep .el-tabs__header{
    line-height: 60px;
  }
  ::v-deep .el-tabs__nav-wrap::after{
    height: 1px;
    background-color: #e8e8e8;
  }
  ::v-deep .el-tabs__item{
    text-align: center;
  }
}
.content{
  background-color: #fff;
  padding: 15px;
}
</style>